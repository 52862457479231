import React from 'react';
import Socslik from './sociajl.jpg';
import Footer from '../Footer';

function Social(){
  return(
    <div>
       <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Social media marketing and management</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
  <p>We can provide you that your brand be seen by persons which are interested in your services or product. We also can create sutning ads just for you</p>

  <p>Our digital marketing experts have more than 15 years experience in this field. We can boost your sales with our marketing campaigns.</p>

  <img src={Socslik} style={{width:'100%'}}/>
  <p>You have pages on social media but you do not have time to update it. If this is your problem than we can solve it. For you we can menage yours socials profiles and update it. We also will promote them to your audiences.</p>

  <p>We are open for all deals contact us.</p>
  


  </div>
  <Footer></Footer>
    </div>
  )
}

export default Social;