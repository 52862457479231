import React from 'react';
import slika from '../htmlcss.png';
import Footer from '../Footer';

function Zaposli(){
  return(
    <div >
     <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">You need web developer?</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Your company have need for hire a experienced web developers (frontend or backend) or grapich designers or digital marketing experts? If this is the case than you can contact us and we'll provide you best fit candidates who adopt your needs.</p>
    <h3>We have own database of experienced web developers who are willing to change their compan</h3>
    <p>Our datebase is fill by real experineced web developers who worked for some of our projects. We have on our disposal web developers juniors, medior and senior levels. This developers are working as freelancers or in the IT companies in Bosnia and Herzegovina. </p>
    <img src={slika} style={{width:'100%'}}/>
    <p>The 70% web developers have experience with 5 or 6 web technologies. This developers have high understanding of web technologies and they have high determination to learn new technologies</p>
    <p>This web developers have interest to leave their current job in Bosnia and Herzegovina and go work somewhere else.</p>
    <p>If you want to hire some of these developers please contact us, give us your terms about tasks and salary, and we'll send you cv of candidate who fits your demands.</p>
    <p>You can contact us via this email<a href="mailto:info@xxlclass.com">info@xxlclass.com</a></p>
  </div>
  <Footer></Footer>
    </div>
  )
}

export default Zaposli;