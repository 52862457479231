import React from 'react';
import phpslika from './php.jpg';
import Footer from '../Footer';

function Php(){
  return(
    <div>
      <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">PHP development</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>

  <div className="container">
    <p>You have need for PHP developer or you wish to have PHP website or webapp, in that case you can call us and we'll give you a best offer for your project. Our team have over 5 years in PHP development</p>
    <img src={phpslika} style={{width:'100%'}}/>
    <p>We can also fix the errors in your code or we can debug your code. </p>
    <h3>Need a small custom form or contact form</h3>
    <p>You have a website and you want custom form or contact form, or you want form that is connected with database than you contact us</p>
  </div>
  <Footer></Footer>
    </div>
  )
}

export default Php;