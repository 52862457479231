import React from 'react';
import slie23 from '../wordpre.jpg'
import Footer from '../Footer';

function Wordpress(){
  return(
    <div>
      <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">WordPress development</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>WordPress is most used CMS for creatin websites around World. Because of that many users are facing with problems on their WordPress sites. We can fix that erorrs for best prices. Hour price of our WP developers can be only 5 EUR.</p>
    <img src={slie23} style={{width:'100%'}}/>
    <p>You have shared hosting with domain name but you do not have someone to create and deply WordPress site in that case we can do it for you with lowes price. We have over 7 years of experienece in WordPress developing. When we agree over terms our team will create mockup of website and deply it on our servers. We'll provide you a link on which you can live see what yours site is looking like.</p>

    <p>Also we can build custom WP theme from yours PSD files. We have built thousands of custom WP themes.</p>

    <h3>Need a new functionality for your WordPress site</h3>
    <p>You have WordPress site which is obsolite and you want to have new functionality of web site. In that case you can contact us and we'll do the rest. We can create redesign of your WordPress site or we can do completly new website.</p>

    <h3>You need maintenance of WordPress site</h3>
    <p>You have WordPress site but you do not have time to edit it or to update it than our team can be your great solution. We can maintenance your WordPress site for only 20 EUR per month.</p>
    
    <h3>Transfer website on the WordPress CMS</h3>
    <p>If you have a website which is not on WordPress platform but you want to migrate it on WordPress we also can help you with that task.</p>
    <h3>WordPress custom theme or plugin</h3>
    <p>You need a custom WordPress theme or plugin? If this is your problem than contact us we can do it for you.</p>
  </div>
  <Footer></Footer>
    </div>
    
  )

}

export default Wordpress;