import React from 'react';
import './Footer.css';


function Footer(){
  return(<div className="footer">
   <div className="row">
     <div className="col-md-4">
     <p>Email <a href="mailto:info@xxlclass.com">info@xxlclass.com</a></p>
     <p>Phone: +38761884830</p>
     <p>Adress: str. Armije BIH</p>
     <p>Tuzla</p>
     <p>Bosnia and Herzegovina</p>

     </div>
     <div className="col-md-4 mapa">
     <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11375.025629018368!2d18.697367!3d44.54062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfa87517098cb58da!2sXXL%20STUDIO!5e0!3m2!1shr!2sba!4v1622460544831!5m2!1shr!2sba" width="100%" height="250" style={{border:'0'}} allowfullscreen="" loading="lazy"></iframe>
     </div>
     <div className="col-md-4">
       <p>We are group of web developer and another IT experts and we offer you best quality for best price on the market. </p>
       <h3>We are based in Tuzla Bosnia and Herzegovina</h3>
       <p>Contact us to develop mutual partnership. We can also work as your office in Bosnia contact us.</p>
     </div>
   </div>

  
  </div>)
}

export default Footer;