import React from 'react';
import Grafiksli from './grafiks.jpg';
import Footer from '../Footer';

function Grafika(){
  return(
    <div>
      <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Graphic design</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>We can create just for you graphic design. We work with all modern tools and modern design.</p>
    <img src={Grafiksli} style={{width:'100%'}}/>
    <p>If you want to boost your presence than graphic design is the best tool for you.</p>
    <p>We can create even the ads for marketing campaigns.</p>
  </div>
  <Footer></Footer>
    </div>
  )
}

export default Grafika;