import React from 'react';
import slik from '../htmlcss.png'
import Footer from '../Footer';
function Template(){
  return(
    <div>
     <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Template developing</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Have website on some of CMS and you do not satisfied with its look. If this is your problem than you can call us and we'll create template just for you</p>
   <img src={slik} style={{width:'100%'}}/>

   <p>We can create template for any CMS system and for custom build websites. Also we can create template for webapps</p>
  </div>
  <Footer></Footer>
    </div>
  )
}

export default Template;