import React from 'react';
import Back from './poza.jpg';
let scope = "{Scope}"

function Jumbotron(){
  return(<div>
     <section className="py-5 text-center " style={{background:"#2c3e50"}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
      <h1 className="fw-light" style={{color:'white'}}>{scope}</h1>
        <h1 className="fw-light" style={{color:'white'}}>Bosnian web developers studio</h1>
        <p  style={{color:'white'}}>We are group of profesional web developers from Bosnia and Herzegovia and we can hellp you with yours web projects as outsourcing company. We can work on any web project even if it is in production stage.  </p>
        <p>
         
          
        </p>
      </div>
    </div>
  </section>
  </div>)
}

export default Jumbotron;