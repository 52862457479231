import React from 'react';
import re from './reac3.jpg';
import Footer from '../Footer';

function Reo(){
  return(
    <div>
      <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">React.js development</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>

  <div className="container">
    <p>We work with React.js for the past 2 years. For you we can build amazing and super speed websites based on React.js framework.</p>
    <img src={re} style={{width:'100%'}}/>
    <p>We can also work on your ongoing project which are based on React.js framework</p>

    <p>Also we can do the maintenance of your websites or web apps which are based on React.js</p>

    <p>For more information contact us</p>

  </div>
  <Footer></Footer>
    </div>
  )
}

export default Reo;