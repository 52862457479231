import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min';
import Nav2 from './Nav';
import Jumbotron from './Jumbotron';
import Services from './Services';
import Footer from './Footer';
import {BrowserRouter,Route} from 'react-router-dom';
import Htmlcss from './Htmlcss';
import Home from './Home';
import About from './About';
import Wordpress from './Services/Wordpress';
import Reo from './Services/Reo';
import Php from './Services/Php';
import Template from './Services/Template';
import Seo from './Services/Seo';
import Social from './Services/Social';
import Grafika from './Services/Grafika';
import Zaposli from './Services/Zaposli';
import Contact from './Contact';
import ScrollToTop from './Services/SrollToTop';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
      <Nav2/>
      <Route exact path="/" component={Home}/>
      <Route exact path="/html-css" component={Htmlcss} onUpdate={() => window.scrollTo(0, 0)} />
      <Route exact path="/about" component={About}/>
      <Route exact path="/wordpress" component={Wordpress}/>
      <Route exact path="/react" component={Reo}/>
      <Route exact path="/php" component={Php}/>
      <Route exact path="/template" component={Template}/>
      <Route exact path="/seo" component={Seo}/>
      <Route exact path="/social" component={Social} />
      <Route exact path="/grapich" component={Grafika}/>
      <Route exact path="/webdeveloper" component={Zaposli}/>
      <Route exact path="/contact" component={Contact}/>
      
    
      </BrowserRouter>
    
     
    </div>
  );
}

export default App;
