import React from 'react';
import poz from './poza.jpg';
import Footer from './Footer';

function Contact(){
  return(
    <div>
       <section className="py-5 text-center " style={{background:`url(${poz})`,backgroundSize:'cover'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light" style={{color:'white',fontWeight:'600'}}>Contact us</h1>
        <p className="lead text-muted"> </p>
        <p>
         
         
        </p>
      </div>
    </div>
  </section>
  <div className="container" style={{marginTop:'50px'}}>
    <p>Contact us via some of this methods</p>
    <h3>Email <a href="mailto:info@xxlclass.com">info@xxlclass.com</a></h3>
    <h3>Phone (WhatsApp): +38761884830</h3>
  </div>
  <Footer></Footer>
    </div>
  )
}

export default Contact;