import React from 'react';
import Jumbotron from './Jumbotron';
import Services from './Services';
import Footer from './Footer';

function Home(){
  return(
    <div>
     <Jumbotron/>
     <Services/>
     <Footer/>
    
    </div>
  )
}

export default Home;