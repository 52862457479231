import React from 'react';
import slika from './htmlcss.png';
import Footer from './Footer';

function Htmlcss(){
  return(<div>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">HTML & CSS development</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>

  <div className="container" style={{marginTop:'60px'}}>
    <h3>We can develop and design for you any kind of HTML & CSS website</h3>
    <p>Our front-end developers will create for you amazing static websites or templates. You can send us yours wishes or you can let us do the work for you</p>
    <img src={slika} style={{width:'100%'}}/>

    <p>If your agency have needs to create this simple templates but you do not have enoguh resources, we'll be your partner. We can arange that we create this templates or websites and to diliver to you. Yours job will be only to diliver this to yours clients as you are build it. For this we can aragne and maintance of this webistes or content menagment. </p>

    <p>You have PSD file with yours desire design that is no problem for us. We can take PSD file and make it to the website or webapp. </p>

    <p>In production stage we use all modern web standards and all code is adopt for all web browsers and devices. </p>
    <p>We have mobile first approach, this approach means that website we first build to fit the smartphones screen than we build it for desktops.</p>

   
    
  </div>
  <Footer></Footer>
  </div>)
}

export default Htmlcss;