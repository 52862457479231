import React from 'react';
import './Services.css';
import {Link} from 'react-router-dom';

function Services(){
  return(
    <div className="container" style={{marginTop:'40px'}}>
        <p>Our goal is to offer you best quality of web development service with lowest price. Because we live and work in Bosnia and Herzegovia we can offer you best posible price for quality service.</p>

        <p>Our web developers, web designers, digital marketing experts all have lot of experience with all modern web development standards</p>

        <p>Our base is in Town Tuzla.</p>
    
    <div className="usluge ">

      <Link to="/html-css">
      <h3>HTML&CSS</h3>
         <p>We create for you HTML & CSS templates and static websites</p>
      </Link>
    
      
      <Link to="/wordpress">
        <h3>WordPress development</h3>
        <p>We can develop for you WordPress themes and WP plugins</p>
        
        </Link>
      <Link to="/react">
        <h3>React.js development</h3>
        <p>We can create websites and webapps on React.js framework</p>
        </Link>
      <Link to="/php">
        <h3>PHP development</h3>
          <p>We can develop any kind of PHP application just for you</p>
        </Link>
      <Link to="/template">
        <h3>Template development</h3>
        <p>Developing any kind of template for popular CMS</p>
        </Link>
      <Link to="/seo">
        <h3>SEO</h3>
        <p>We can provide better organic reach of yours websites</p>
        </Link>
      <Link to="/social">
        <h3>Social media marketing</h3>
        <p>We can manage and promote your social media pages</p>
        </Link>
      <Link to="/grapich">
        <h3>Graphic design</h3>
        <p>Our designers can create graphic design just for you</p>
        </Link>
      
      <Link to="/webdeveloper">
        <h3>Hire web developer</h3>
        <p>Need a web developer to hire, we can help you</p>
        </Link>
        </div>
    </div>
  )
}

export default Services;