import React from 'react';
import Seosli from './seo.jpg';
import Footer from '../Footer';

function Seo(){
  return(
    <div>
       <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Search engine optimization</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>You have website but your organic reach is very por? If this is your problem than we can help you to be in top 5 first searches for target keywords.</p>
    <p>This is not the paid traffic through ads this is the organic reach.</p>
    <img src={Seosli} style={{width:'100%'}}/>
    <p>To achieve this we combine various techniques.Our team have over 11 years of experience in this field. We follow the policy of web search companies and we adopt algorithm to our clients.</p>
    <p>For offer you can contact us via this email <a href="mailto:info@xxlclass.com">info@xxlclass.com</a></p>

  </div>
  <Footer></Footer>
    </div>
  )
}

export default Seo;