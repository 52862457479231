import React from 'react';
import poz from './poza.jpg';
import Footer from './Footer';

function About(){
  return(<div>
     <section className="py-5 text-center " style={{background:`url(${poz})`,backgroundSize:'cover'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light" style={{color:'white',fontWeight:'600'}}>About us</h1>
        <p className="lead text-muted"> </p>
        <p>
         
         
        </p>
      </div>
    </div>
  </section>
  <div className="container" style={{marginTop:'60px'}}>
    <h3>Who are we?</h3>
    <p>We are web developers studio from Bosnia and Herzegovina. We are located at city of Tuzla. In our team we have 3 front end developers, 2 back end developers, 1 UX/UI, 3 digital marketing experts, 3 grapich designers</p>

    <p>We work with all modern web standards. For you we can develop any kind of website and webapp. Also we can be your outsourcing partner and work together with you on yours project</p>

    <p>For you we can work all range of services from designing and developing simple web site to the working on large web aplications. We have resoures for simple tasks like adding content, maintanenece websites, designing the website modules, creting marketing ads and etc</p>

    <p>If you have open position for web developer, grapich designer, digital marketing expert you can contact us. We'll find you situabile person for yours position. We have big database of web developers from Bosnia and Herezegovina who finished our internship. </p>

    <p>If Bosnia and Herzegovina is interesting for you because here salary of web developers is whay more less than in your country and you thinking about establishing office of yours company here you can also contact us. We can do all job for you about establishing yours office also we can provide you a best employes for your office</p>

    <p>We guarantee that all services we provided will be best quality and it will be finished on time we agreed on.</p>
    <p>You can contact us via phone (WhatsApp) +38761884830 or via email <a href="mailto:info@xxlclass.com">info@xxlclass.com</a></p>
  </div>
  <Footer></Footer>
  </div>)
}

export default About;